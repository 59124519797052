import React from 'react'
import { get } from 'lodash'
import Layout from '../components/layout/Layout'
import SEO from '../components/seo/SEO'
import { graphql } from 'gatsby'

class ContactUs extends React.Component {
  render() {
    const pageTitle = 'Weighted Blanket Giveaway'
    const pageDescription = 'Weighted Blanket Guides is happy to announce the latest Giveaway! The lastest is our Black Friday 2022 Giveaway!'

    const siteConfig = get(this, 'props.data.site.siteMetadata')
    const seoConfig = {
      ...siteConfig,
      url: get(this, 'props.location.href', `https://www.weightedblanketguides.com/giveaways/`),
      title: `${pageTitle} - ${get(this, 'props.data.site.siteMetadata.name')}`,
      description: pageDescription
    }

    const content = `
      <h3>Black Friday 2022</h3>
      <p>Weighted Blanket Guides is happy to announce the latest giveaway to celebrate Black Friday!</p>
      <p><strong>Register and complete the following tasks</strong> in order to <strong>earn more entries</strong> and increase the chance of winning.</p>
      <div id="63800-5gw7v8lb" class="sw_container"><script type="text/javascript" src="https://sweepwidget.com/w/j/w_init.js"></script></div>
      `

    return (
      <Layout location={this.props.location}>
        <SEO postData={{}} postImage={''} isBlogPost={false} config={seoConfig}/>
        <div className="page__container">
          <h1>{pageTitle}</h1>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      </Layout>
    )
  }
}

export default ContactUs

export const ContactUsQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        name
        description
        siteUrl
      }
    }
  }
`
